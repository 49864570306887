import React from 'react'
import { LinkButton, Section } from 'src/components/shared'

import styles from './fourOhFour.module.scss'

export default () => (
  <div className={styles.wrapper}>
    <Section className={styles.container}>
      <h1 className={styles.title}>404 - Page Not Found</h1>
      <span className={styles.text}>
        Sorry, this page doesn't exist. Please head home.
      </span>
      <LinkButton className="Btn Btn--medium Btn--primary" to="/">
        Go Home
      </LinkButton>
    </Section>
  </div>
)
