import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Navbar } from '../components/shared'
import FourOhFour from '../components/pages/FourOFour'
import Footer from '../components/pages/Home/Footer'

const NotFoundPage = () => (
  <Layout>
    <Helmet title="Meerkat - 404" />
    <Navbar />
    <FourOhFour />
    <Footer />
  </Layout>
)

export default NotFoundPage
